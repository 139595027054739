@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "theme.scss";
@import "~@fontsource/roboto/300.css";

html, body {;
    min-height: 100%;
    height: 100%;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
}

body {
    background-color: red;
}

@import "~bootstrap/scss/bootstrap";
