@use "sass:map";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/breakpoints";

// Typography
$font-family-sans-serif: 'Roboto', sans-serif;
$headings-font-weight: 300;
$font-weight-lighter: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 500;
$font-weight-bolder: 700;
$font-size-base: 1rem;

$primary: #0071bc;
$secondary: #29abe2;
$success: #5aba7a;
$danger: #d32432;
$warning: #f6a35c;
$info: #ff4136;
$light: #ffffff;
$dark: #000000;
$accent1: #098cc6;
$accent2: #2389ad;
$accent3: #0067a0;
$special1: #ff1d25;
$special2: #fcee21;

$gray-100: #f2f2f2;
$gray-200: #e6e6e6;
$gray-300: #cccccc;
$gray-400: #b3b3b3;
$gray-500: #999999;
$gray-600: #808080;
$gray-700: #666666;
$gray-800: #4d4d4d;
$gray-900: #333333;

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
    "accent1": $accent1,
    "accent2": $accent2,
    "accent3": $accent3,
    "special1": $special1,
    "special2": $special2
);
$grays: (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
);

$grid-breakpoints: (
    us: 0,
    xs: 375px,
    sm: 480px,
    md: 768px,
    lg: 1024px,
    xl: 1600px,
    xxl: 1920px,
    xxxl: 2300px
);

$container-max-widths: (
    sm: 670px,
    md: 900px,
    lg: 1600px,
    xl: 1920px,
    xxl: 2200px
);

$spacers: (
    6: ($spacer * 3.5),
    7: ($spacer * 4),
    8: ($spacer * 4.5),
    9: ($spacer * 5),
    10: ($spacer * 5.5),
    11: ($spacer * 6),
    12: ($spacer * 6.5),
    13: ($spacer * 7),
    14: ($spacer * 7.5),
    15: ($spacer * 8),
    16: ($spacer * 8.5),
    17: ($spacer * 9),
    18: ($spacer * 9.5),
    19: ($spacer * 10),
    20: ($spacer * 10.5),
    21: ($spacer * 11),
    22: ($spacer * 11.5),
    23: ($spacer * 12),
    24: ($spacer * 13)
);

$spinner-border-width-sm: 2px;
